div.container {
  align-self: center;
  height: calc((var(--vh, 1vh) * 100) - var(--AppHeader) - var(--AppFooter) );
  width: 100%;
  z-index: 1;

  div.AppBody {
    height: calc((var(--vh, 1vh) * 100) - var(--AppHeader) - var(--AppFooter) );
  }
}

@media screen and (max-width: 600px) {
  div.container {
    height: calc((var(--vh, 1vh) * 100) - 100px );
    div.AppBody {
      width: 100vw;
      height: calc((var(--vh, 1vh) * 100) - 100px );
      /* display: flex;
      flex-flow: column-reverse wrap; */
    }
  }
}
