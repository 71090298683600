div.Container {
    justify-self: right;
    flex-flow: column nowrap;
    flex: 1;
    margin: 0 0 5px 0;
    border-radius: var(--brdRadius);
    background-color: var(--mainColor);
    min-height: 30px;
    color: var(--mainWhiteOrBlack);
    padding: 5px;
    white-space: pre-line;
    .timeSign {
        /* flex: 1;
        flex-flow: row wrap; */
       
    }

    /* position: relative; */

  /* &:last-of-type {
    margin-bottom: 0px;
  } */

    div.whDiv {
      /* text-align: center; */
      /* display: flex; */
      flex: 1;
      min-height: 30px;
      flex-flow: row wrap;
      /* justify-content: space-between; */
      color: var(--mainWhite);
      padding: 5px;
      white-space: pre-line;
    }


  }

